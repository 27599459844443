@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:wght@100;300;400;500;700&display=swap");
@import "variables";
@import "reset";

body {
  width: 100%;
  max-width: 1100px;
  background-color: $black;
  color: $white;
  font-family: "Roboto", sans-serif;
  padding: 20px;
  margin: 0 auto;

  @media screen and (min-width: 750px) {
    font-size: 1.2rem;
    padding: 40px;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
  }

  #root {
    height: 100%;

    .app {
      height: 100%;

      .navContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        gap: 1rem;

        @media screen and (min-width: 750px) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 50px;
        }

        #logo {
          height: 60px;
          margin-left: -5px;

          @media screen and (min-width: 750px) {
            height: 28px;
          }
        }

        .navLinkContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media screen and (min-width: 750px) {
            display: flex;
            flex-direction: row;
          }

          .logoAndHamburger {
            display: flex;
            justify-content: space-between;
            width: 100%;

            #hamburger {
              width: 60px;
              height: 45px;
              position: relative;
              //margin: 50px auto;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transition: 0.5s ease-in-out;
              -moz-transition: 0.5s ease-in-out;
              -o-transition: 0.5s ease-in-out;
              transition: 0.5s ease-in-out;
              cursor: pointer;

              @media screen and (min-width: 750px) {
                display: none;
              }
            }

            #hamburger span {
              display: block;
              position: absolute;
              height: 9px;
              width: 100%;
              background: $white;
              border-radius: 9px;
              opacity: 1;
              left: 0;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
              -webkit-transition: 0.25s ease-in-out;
              -moz-transition: 0.25s ease-in-out;
              -o-transition: 0.25s ease-in-out;
              transition: 0.25s ease-in-out;
            }

            #hamburger span:nth-child(1) {
              top: 0px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            #hamburger span:nth-child(2) {
              top: 18px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            #hamburger span:nth-child(3) {
              top: 36px;
              -webkit-transform-origin: left center;
              -moz-transform-origin: left center;
              -o-transform-origin: left center;
              transform-origin: left center;
            }

            #hamburger.open span:nth-child(1) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              top: -3px;
              left: 8px;
            }

            #hamburger.open span:nth-child(2) {
              width: 0%;
              opacity: 0;
            }

            #hamburger.open span:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              top: 39px;
              left: 8px;
            }
          }

          #navLinks {
            display: none;

            @media screen and (min-width: 750px) {
              display: flex;
              flex-direction: row;
            }
          }

          #navLinks.show {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: right;
            text-transform: uppercase;
            gap: 1rem;
            margin-top: 10px;
            animation: navLinkAnim 1s;

            @media screen and (min-width: 750px) {
              display: flex;
              flex-direction: row;
              animation: none;
              text-transform: capitalize;
              align-items: center;
              margin-top: 0;
              gap: 0;
            }
          }
        }

        .userContainer {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          gap: 1rem;

          @media screen and (min-width: 750px) {
            flex-direction: row;
            justify-content: flex-end;
            gap: 2rem;
            height: 50px;
          }

          img {
            width: 50px;

            @media screen and (min-width: 750px) {
              max-height: 100%;
            }
          }
        }
      }

      a {
        color: $white;
        margin: 0 10px 0 0;
        text-decoration: none;

        &:hover {
          color: $blue;
        }
      }

      #landingLogin {
        height: 72px;
        width: 72px;
        border-radius: 50%;
      }

      .showMoreBtn {
        background-color: $black;
        color: $white;
        padding: 0.3rem 0.8rem;
        margin: 20px 0 20px;
        border-radius: 5px;
        border: none;
        outline: none;
        filter: drop-shadow(0 0 0.3rem $white);
        font-weight: 500;
        font-size: 1.2rem;

        &:hover {
          color: $blue;
          cursor: pointer;
        }

        &:disabled {
          color: $gray;
        }
      }

      .popup {
        margin-bottom: 4rem;
      }

      .deleteConfirm {
        font-size: 30px;
        background-color: $black;
        color: $white;
        padding: 0.3rem 0.5rem;
        margin: 0.6rem;
        border-radius: 5px;
        border: none;
        outline: none;
        filter: drop-shadow(0 0 0.3rem $white);

        margin-left: 5px;
        font-weight: 500;
        font-size: 1.2rem;

        &:hover {
          color: $blue;
          cursor: pointer;
        }
      }

      .homeContainer {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-bottom: 1rem;

        img,
        iframe {
          max-width: 100%;
        }

        img {
          @media screen and (min-width: 750px) {
            width: 100%;
          }
        }

        iframe {
          @media screen and (min-width: 750px) {
            height: 540px;
            width: 960px;
          }
        }

        .titleContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 2rem;
          margin-bottom: 2rem;
        }

        .apiDescription {
          color: $gray;
          font-weight: 400;
          margin-bottom: 3rem;
        }

        .apodMain {
          width: 100%;

          .apodContainer {
            .apodText {
              margin-bottom: 2rem;
            }
            .imgWrapper {
              text-align: center;
              width: 100%;
              margin-bottom: 2rem;
            }
            p {
              line-height: 1.2rem;
              text-align: justify;
            }
          }
        }

        .issMain {
          .issData {
            margin-bottom: 2rem;
          }
        }

        .peopleMain {
          width: 100%;

          .peopleContainer {
            display: flex;
            flex-direction: column;

            .lists {
              display: flex;
              flex-direction: row;
              gap: 3rem;

              @media screen and (min-width: 750px) {
                gap: 5rem;
              }

              .station {
                width: 50%;

                @media screen and (min-width: 750px) {
                  width: 30%;
                }

                .craft {
                  border-bottom: 1px solid $white;
                  margin-bottom: 1.5rem;
                }

                p {
                  line-height: 2rem;
                }
              }
            }
          }
        }
      }

      .setUsernameContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;

        @media screen and (min-width: 750px) {
          width: 30%;
        }
      }

      .filterUsersContainer,
      .setUsernameContainer,
      .dateContainer {
        input {
          outline: none;
          color: $white;
          caret-color: $white;
          background-color: $black;
          border: none;
          border-bottom: 1px solid $white;
          margin-bottom: 2rem;
          font-family: Roboto;

          &#pickDate {
            margin-left: 1rem;
          }

          &::-webkit-calendar-picker-indicator {
            color: $white;
            filter: invert(100%);
          }
        }
      }

      .filterUsersContainer {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 1rem;

        .listedUser {
          display: flex;
          align-items: center;
        }

        img {
          width: 50px;
          margin-right: 10px;
        }
      }

      .missionContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .mission {
          line-height: 2rem;
        }
      }

      .factMain {
        .factContainer {
          .fact {
            line-height: 2rem;
            text-align: justify;

            p {
              text-align: right;
              color: $gray;
            }
          }
        }
      }

      .people,
      .spaceStation,
      .apod {
        line-height: 2rem;
      }

      .landingPage {
        height: 100%;

        .landingContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 3rem;

          @media screen and (min-width: 750px) {
            height: 100%;
          }

          #landing {
            height: 160px;
            width: 160px;
            background-image: url("../img/loadingSmall.gif");
            background-repeat: no-repeat;
            object-fit: contain;
            background-position: center;

            @media screen and (min-width: 750px) {
              height: 280px;
              width: 280px;
              background-image: url("../img/loading.gif");
            }
          }
        }
      }
    }
  }
}

@keyframes navLinkAnim {
  from {
    transform: translateY(-220px);
  }
  to {
    transform: translateY(0);
  }
}
